.main {
  @apply flex flex-col justify-start items-center overflow-hidden h-full;
}

.main-section {
  @apply flex flex-col justify-start items-center relative;
}

.main-section::after {
  content: "";
  background-image: url("/assets/resources/home-gradient.png");

  @apply absolute bottom-0 left-0 w-full h-20 z-10;
}

.container {
  @apply max-w-6xl w-full mx-auto
}

